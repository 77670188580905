import { availableApps } from './availableApps';

export const appRedirectionSchemas = {
  wolfearn: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.wolfearn),
  cashmamba: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashmamba),
  mathschamp: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.mathschamp),
  cashzebra: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashzebra),
  cashbear: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashbear),
  cashwarrior: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashwarrior),
  cashgorilla: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashgorilla),
  rewardsprint: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.rewardsprint),
  earnbucks: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.earnbucks),
  rewardfreak: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.rewardfreak),
  rewardmax: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.rewardmax),
  grabreward: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.grabreward),
  earnsamurai: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.earnsamurai),
  cashrhino: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashrhino),
}