import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Grid, useMediaQuery } from '@mui/material'; // Import useMediaQuery
import { motion } from "framer-motion";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { latestGames } from '../../../constants/lastestApps';
import { textConstants } from '../../../constants/textConstants';
import Title from '../../common/Title';
import './customStyles.css';
import { renderItem } from './Helpers';

const LatestGames = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 600px)'); // Define mobile breakpoint

    const handleViewMore = () => {
        navigate('/AllGames');
    };

    return (
        <Box
            sx={{
                background: 'white',
                borderRadius: '12px',
                paddingTop: '24px'
            }}
            whileHover={{ scale: 1.1 }}
            component={motion.div}
        >
            <div id="gamesElement" />
            <Title text={textConstants?.ourLastestApps} textAlign="center" />
            <motion.div
                initial={{
                    opacity: 0,
                    x: 50
                }}
                whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                        duration: 1
                    }
                }}
                viewport={{ once: false }}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}
            >
                <Grid
                    container
                    gap={4}
                    sx={{ justifyContent: 'center', my: '32px' }}
                >
                    {/* Show 3 games on mobile, 5 games on larger screens */}
                    {latestGames.slice(0, isMobile ? 3 : 5).map(renderItem)}

                    <Button
                        onClick={handleViewMore}
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(to right, #ff7e5f, #feb47b)',
                            borderRadius: '24px',
                            padding: '12px 24px',
                            color: 'white',
                            textTransform: 'none',
                            fontStyle: 'italic',
                            fontFamily: 'Arial, sans-serif',
                            fontWeight: 'bold',
                            width: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                background: 'linear-gradient(to right, #feb47b, #ff7e5f)',
                                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)',
                                transform: 'translateY(-3px)',
                            },
                        }}
                        endIcon={<ArrowForwardIosIcon />}
                    >
                        View More
                    </Button>
                </Grid>
            </motion.div>
        </Box>
    );
};

export default LatestGames;
